import React from 'react';
import css from "./NotFound.module.css"
import {Link} from "react-router-dom";
const NotFound = () => {

    return (
        <div className={css.container}>
            <div className={css.textNotFound}> 404 </div>
            <div className={css.textNotFound}> Page not found </div>
            <div className={css.buttonSpacing}>
                <Link to="/" className={css.buttonBack}> Back to main page </Link>
            </div>
        </div>
    );
};

export default NotFound;