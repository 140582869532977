import React from 'react';
import css from "./FeatureBox.module.css";

const FeatureBox = ({imagePath, title, description}) => {
    return (
        <div>
            <div className={css.container}>
                <div className={css.col1}>
                    <div className={css.imageContainer}>
                        <img className={css.image} alt='' src={require("./"+imagePath)}/>
                    </div>
                </div>
                <div className={css.col2}>
                    <div className={css.title}>{title}</div>
                    <div className={css.description}>{description}</div>
                </div>
            </div>
        </div>
    );
};


export default FeatureBox;