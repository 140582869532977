import React from 'react';
import PropTypes from 'prop-types';
import css from './MainPage.module.css'
import { slide as Menu } from 'react-burger-menu'
import {NavLink} from "react-router-dom";
import FeatureBox from "../component/FeatureBox";

const MainPage = props => {

    var styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '36px',
            top: '36px'
        },
        bmBurgerBars: {
            background: '#373a47'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: '#373a47',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em'
        },
        bmItem: {
            display: 'inline-block'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }

    return (
        <>
            <div className={css.main}>
                <div className={css.topBar}>
                    <div className={css.logoContainer}>
                        <img className={css.image} src={require('./blackPilowLogo.png')} />
                    </div>
                    <div className={css.filler}></div>
                    {/*<a href="https://app.pilow.io/login"><div className={css.buttonText}>Platform</div></a>*/}
                    {/*<a href="https://app.pilow.io/login"><div className={css.buttonText}>Resources</div></a>*/}
                    {/*<a href="https://app.pilow.io/login"><div className={css.buttonText}>Company</div></a>*/}
                    <a href="mailto:hello@pilow.io"><div className={css.getStartedButton}>Ask us</div></a>
                </div>
                <div className={css.body}>
                    <div className={css.mainContainer}>
                        <div className={css.titleText}>The Leading Generative AI for <div style={{color:"#F96553", display:"inline-block"}}>Onboarding</div> Developers</div>
                        <div className={css.paragraphContainer}>
                            <div className={css.paragraphText}>
                                Meet <div style={{color:"#F96553", display:"inline-block"}}>OnboardingGPT,</div> the generative AI helping engineering teams easily cut costs while providing top-tier onboarding experience.
                            </div>
                        </div>
                        {/*<NavLink to="/waitlist"><div className={css.getStartedButton2}>Join waitlist</div></NavLink>*/}
                    </div>
                    <div className={css.chatContainer}>
                        <img className={css.chatImage} src={require('./chat.png')} />
                        <img className={css.personImage} src={require('./person.png')} />
                    </div>
                </div>
            </div>
            <div className={css.breakRules}></div>
            <div className={css.blackStrip}>
                <div className={css.blackStripCol1}>
                    <div className={css.blackStripCol1Title}>Doing more with less is the new business imperative.</div>
                    <div className={css.blackStripCol1Paragraph}>Pilow’s uses Large Language Models (LLMs) and fine-tunes them on your own data to maximize efficiency of onboarding your employees.</div>
                </div>
                <div className={css.blackStripCol2}>
                    <div className={css.blackStripCol2row}>
                        <div className={css.blackStripCol2rowCol1}>
                            <div style={{color:"lightskyblue"}} className={css.blackStripCol2rowCol1Title}>
                                82%
                            </div>
                            <div className={css.blackStripCol2rowCol1Paragraph}>
                                increase in new hire retention
                            </div>
                        </div>
                        <div className={css.blackStripCol2rowCol2}>
                            <div style={{color:"hotpink"}} className={css.blackStripCol2rowCol1Title}>
                                70%
                            </div>
                            <div className={css.blackStripCol2rowCol1Paragraph}>
                                improve in the productivity
                            </div>
                        </div>
                        <div className={css.blackStripCol2rowCol3}>
                            <div style={{color:"orange"}} className={css.blackStripCol2rowCol1Title}>
                                88%
                            </div>
                            <div className={css.blackStripCol2rowCol1Paragraph}>
                                organizations don’t onboard well
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

MainPage.propTypes = {

};

export default MainPage;