import './App.css';
import {Route, Routes} from "react-router-dom"
import MainPage from "./pages/MainPage"
import NotFound from "./pages/NotFound";


function App() {

  return <div>
            <Routes>
                <Route path="/" element={<MainPage/>} />
                <Route path="*" element={<NotFound/>} />
            </Routes>
        </div>
}

export default App;
